import IndexedDbHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';

import type Database from 'o365.pwa.modules.client.dexie.objectStores.Database2.ts';
import type PWAState from 'o365.pwa.modules.client.dexie.objectStores.PWAState.ts';
import type ServiceWorkerState from 'o365.pwa.modules.client.dexie.objectStores.ServiceWorkerState.ts';

import type * as AppModule from 'o365.pwa.declaration.shared.dexie.objectStores.App.d.ts';

export class App implements AppModule.App {
    static objectStoreDexieSchema: string = "&id";

    id!: string;
    title?: string;
    icon?: string;

    public get databases() {
        const app = this;

        return new Proxy<AppModule.Databases>(<AppModule.Databases>{
            getAll: async () => {
                return await IndexedDbHandler.getDatabases(app.id);
            }
        }, {
            get(target, prop, receiver) {
                if (prop in target) {
                    return Reflect.get(target, prop, receiver);
                }

                return new Promise<Database | null>(async (resolve, reject) => {
                    try {
                        const database = await IndexedDbHandler.getDatabase(app.id, prop.toString());
                    
                        resolve(database);
                    } catch (reason) {
                        reject(reason);
                    }
                });
            }
        });
    }

    public get pwaState(): Promise<PWAState | null> {
        return new Promise(async (resolve, reject) => {
            try {
                const pwaState = await IndexedDbHandler.getPWAState(this.id);

                resolve(pwaState);
            } catch (reason) {
                reject(reason);
            }
        });
    }

    public get serviceWorkerState(): Promise<ServiceWorkerState | null> {
        return new Promise(async (resolve, reject) => {
            try {
                const serviceWorkerState = await IndexedDbHandler.getServiceWorkerState(this.id);

                resolve(serviceWorkerState);
            } catch (reason) {
                reject(reason);
            }
        });
    }

    public get isAppInstalled(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                const pwaState = await this.pwaState;
                const serviceWorkerState = await this.serviceWorkerState;

                const isAppInstalled = pwaState?.isAppInstalled ?? false;
                const isServiceWorkerInstalled = serviceWorkerState?.installed ?? false;

                resolve(isAppInstalled && isServiceWorkerInstalled);
            } catch (reason) {
                reject(reason);
            }
        });
    }

    public async deleteAppDatabases(): Promise<void> {
        const databases = await this.databases.getAll();
        
        for (const database of databases) {
            const dexieInstance = await database.dexieInstance;
            const objectStores = await database.objectStores.getAll();

            for (const objectStore of objectStores) {
                const dexieTable = dexieInstance.table(objectStore.id);
                await dexieTable.clear();
            }
        }
    }

    constructor(id: string, title?: string, icon?: string) {
        this.id = id;
        this.title = title;
        this.icon = icon;
    }

    public async save(): Promise<void> {
        await IndexedDbHandler.updateApp(this);
    }

    public async delete(): Promise<void> {
        await IndexedDbHandler.deleteApp(this);
    }

    public async forceReload(): Promise<App | null> {
        return await IndexedDbHandler.getAppFromIndexedDB(this.id);
    }

    public async initialize(): Promise<void> {
        const databases = await this.databases.getAll();

        for (const database of databases) {
            await database.initialize();
        }
    }
}

export default App;
